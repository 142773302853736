import React, {Component} from 'react';
import { Breadcrumb, BreadcrumbItem, Media } from 'reactstrap';
import { Link } from 'react-router-dom';


class Activities extends Component{

    render(){
        return(
            <div className="container">
                <div className="row">
                    <Breadcrumb>
                        <BreadcrumbItem><Link to="/home">SEPCH</Link></BreadcrumbItem>
                        <BreadcrumbItem active>Activities</BreadcrumbItem>
                    </Breadcrumb>
                    <div className="col-12">
                        <h3>Activities</h3>
                        <hr />
                    </div>               
                </div>
                <div className="row photoborder">
                <div className="col-12" style={{textAlign:"center",fontStyle:"italic"}}>
                    <img object src="asset/images/Activities_Aug2024.jpg" className="img-fluid" alt="activities01"></img>
                </div>
            </div>
                <br></br>
            </div>
        );
    };
}

export default Activities;